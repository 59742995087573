export const WHEELHOUSE_2023_01_09_TOOLS_DEBUG = 'wheelhouse_2023_01_09_tools_debug'
export const WHEELHOUSE_20230516_YACHTS = 'wheelhouse_20230516_yachts'
export const WHEELHOUSE_20230711_PRODUCT_ONBOARDING = 'wheelhouse_20230711_product_onboarding'
export const WHEELHOUSE_20230718_PROPOSAL_STYLE_CONFIG_HEIGHT_OVERFLOW =
  'wheelhouse_20230718_proposal_style_config_height_overflow'
export const WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE = 'wheelhouse_20230801_wysiwyg_proposal_in_mobile_mode'
export const WHEELHOUSE_20230809_FIX_MAP_MULTIPLE_LOCATIONS_TO_SHOW_IN_READONLY_VIEWS =
  'wheelhouse_20230809_fix_map_multiple_locations_to_show_in_readonly_views'
export const WHEELHOUSE_20230919_YACHT_SUMMARY_CARD_SECTION = 'wheelhouse_20230919_yacht_summary_card_section'
export const WHEELHOUSE_20240228_VESSEL_SLIDE = 'wheelhouse_20240228_vessel_slide'
export const WHEELHOUSE_20240509_SAVING_INDICATOR = 'wheelhouse_20240509_saving_indicator'
export const WHEELHOUSE_20240517_APP_NAVIGATION = 'wheelhouse_20240517_app_navigation'
export const WHEELHOUSE_20240619_ONBOARDING_PAGE = 'wheelhouse_20240619_onboarding_page'
export const WHEELHOUSE_20240622_DARK_MODE_TRIPS = 'wheelhouse_20240622_dark_mode_trips'
export const WHEELHOUSE_20240625_ONBOARDING_INVITE_COLLEAGUES = 'wheelhouse_20240625_onboarding_invite_colleagues'
