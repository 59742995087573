import { createApp } from 'vue'
import VueObserveVisibility from 'vue-observe-visibility'
import { Router } from 'vue-router'
import { sentryVueInit } from '@ankor-io/sentry-conflux/src/sentry'
import { iamPredicates } from '@ankor-io/common/auth/client/predicates'

import App from '@/App.vue'
import { apolloClientInstance } from '@/apollo/apolloClient'
import { fetchConfig } from '@/config/fetch'
import { useDiffSyncPlugin } from '@/diffsync/plugin'
import { createAuthentication } from '@/iam/authentication'
import createRouter from '@/router'
import { createSearchClient } from '@/search/client'
import '@/style.css'
import { growthbookInstance } from '@/utils/growthbook'
import { initializeGtm } from '@/utils/gtm'

// First load the config
const config = await fetchConfig()
// Get the authentication context
const authenticationContext = await createAuthentication(config.AUTHENTICATION_CLIENT_OPTIONS)

/**
 * Track application errors
 */
window.onerror = function (msg, _url, _line, _col, error) {
  //@ts-ignore
  window.heap?.track('applicationError', {
    message: msg,
    stack: error?.stack,
  })
  console.log(error)
}

/**
 * Track application promises rejections
 */
window.addEventListener('unhandledrejection', function (event) {
  //@ts-ignore
  window.heap?.track('applicationError', {
    message: event.reason.message,
    stack: event.reason.stack,
  })
  console.log(event)
})

// Create the router
const router: Router = createRouter(config)

// Install the navigation guards
router.beforeEach(async (to) =>
  iamPredicates(authenticationContext, config.AUTHENTICATION_CLIENT_OPTIONS).beforeEach(to),
)

const app = sentryVueInit(createApp(App), config.SENTRY)
app.use(config)
app.use(router)
app.use(useDiffSyncPlugin({ wsUrl: config.WS_URL }))
app.use(initializeGtm(config, router))
app.use(authenticationContext)

// Get a token for one-time-use mechanisms, e.g. build search key.
const _token = await authenticationContext.getToken()
if (!_token) {
  authenticationContext.redirectToLogin()
}


const organizations = await authenticationContext.getUserOrganizations()

if (
  config.AUTHENTICATION_CLIENT_OPTIONS?.default_org_code &&
  !organizations.orgCodes.includes(config.AUTHENTICATION_CLIENT_OPTIONS.default_org_code)
) {
  // Add the user to the default organization if not already added
  await fetch(`/api/iam/organization/${config.AUTHENTICATION_CLIENT_OPTIONS.default_org_code}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${_token}`,
    },
  })
}

const growthbook = await growthbookInstance.createGrowthbookClient(
  {
    clientKey: config.GROWTHBOOK.clientKey,
    enableDevMode: true,
  },
  authenticationContext,
)
app.use(growthbook)
//
try {
  const searchClient = await createSearchClient(_token, config.ALGOLIA.app_id)
  app.use(searchClient)
} catch (error) {
  authenticationContext.redirectToLogin()
}

//
app.use(VueObserveVisibility)

app.use(apolloClientInstance.createApolloClient(authenticationContext))

/**
 * Track vue application errors
 */
app.config.errorHandler = (err: any, vm: any, info: any) => {
  //@ts-ignore
  window.heap?.track('applicationError', {
    error: err.message,
    stack: err.stack,
    info: info,
    vm: vm,
  })
  console.log(err)
}

app.mount('#app')
